import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setShowPrivacyPolicy } from "../../store/user-acceptances/UserAcceptancesSlice";

interface ThemedLinkProps {
  primary?: boolean;
}

const ThemedLink = styled.a<ThemedLinkProps>`
  position: relative;
  font-family: "Source Sans", sans-serif;
  color: ${(props) => (props.primary ? "#e01a22" : "#6e6e6e;")};
  display: inline-block;
  text-decoration: underline;
  outline: none;
  cursor: pointer;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: -5px;
    bottom: -2px;
    right: -5px;
    border-radius: 2px;
    transition: box-shadow 0.2s;
  }

  &:hover,
  &:focus {
    &::before {
      box-shadow: 0 0 0 2px
        ${(props) => (props.primary ? "#e01a22" : "#6e6e6e;")};
    }
  }
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 14px;

  @media (max-width: 460px) {
    &::before {
      width: 85%;
      content: "";
      border-bottom: 1px solid #e5e5e5;
      bottom: -30px;
    }
  }
`;

const FooterLinks = styled.div`
  text-align: center;
  text-decoration: underline;
`;

export function Footer() {
  const dispatch = useDispatch();
  return (
    <FooterContent>
      <FooterLinks>
        <p>
          <ThemedLink
            data-testid="footer-privacy-policy-link"
            href=""
            onClick={(e) => {
              e.preventDefault();
              dispatch(setShowPrivacyPolicy(true));
            }}
          >
            Coles Group Privacy Policy
          </ThemedLink>
        </p>
      </FooterLinks>
    </FooterContent>
  );
}
