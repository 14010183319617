import { getMetaTagContent } from "./ConfigurationHelpers";

export interface Configuration {
  serverUrl: string;
  flybuysLogoutUrl: string;
  isDevelopmentToolsEnabled: boolean;
  allowedReturnUrls: string[];
  enablePostcodeFeature: boolean;
  receiptFeature: boolean;
  instoreReceiptFeature: boolean;
  secondaryEmailReceiptBusiness: boolean;
  secondaryEmailReceiptPersonal: boolean;
  receiptPreferencesMainSectionBusiness: boolean;
  receiptPreferencesMainSectionPersonal: boolean;
  onlineShoppingReceiptBusiness: boolean;
  onlineShoppingReceiptPersonal: boolean;
  securityPreferenceCustomerType: boolean;
}

let configuration: Configuration;
if (process.env.NODE_ENV !== "production") {
  configuration = {
    serverUrl: "http://localhost:5006",
    flybuysLogoutUrl:
      "https://auth1.flybuys-dev.com.au/v2/logout?returnTo=http%3A%2F%2Fcoles.com.au&client_id=partner-coles-app-dev",
    isDevelopmentToolsEnabled: true,
    allowedReturnUrls: ["https://google.com"],
    enablePostcodeFeature: true,
    receiptFeature: true,
    instoreReceiptFeature: true,
    secondaryEmailReceiptBusiness: true,
    secondaryEmailReceiptPersonal: true,
    receiptPreferencesMainSectionBusiness: true,
    receiptPreferencesMainSectionPersonal: true,
    onlineShoppingReceiptBusiness: true,
    onlineShoppingReceiptPersonal: true,
    securityPreferenceCustomerType: true,
  };
} else {
  configuration = {
    serverUrl: getMetaTagContent("server-url"),
    flybuysLogoutUrl: getMetaTagContent("flybuys-logout-url"),
    isDevelopmentToolsEnabled: false,
    allowedReturnUrls: parseReturnUrls("allowed-return-urls"),
    enablePostcodeFeature: isFeatureEnabled("postcode-feature-toggle"),
    receiptFeature: isFeatureEnabled("receipt-page-feature-toggle"),
    instoreReceiptFeature: isFeatureEnabled(
      "instore-receipt-page-feature-toggle"
    ),
    secondaryEmailReceiptBusiness: isFeatureEnabled(
      "secondary-email-receipt-business-feature-toggle"
    ),
    secondaryEmailReceiptPersonal: isFeatureEnabled(
      "secondary-email-receipt-personal-feature-toggle"
    ),
    receiptPreferencesMainSectionBusiness: isFeatureEnabled(
      "receipt-preferences-main-section-business-feature-toggle"
    ),
    receiptPreferencesMainSectionPersonal: isFeatureEnabled(
      "receipt-preferences-main-section-personal-feature-toggle"
    ),
    onlineShoppingReceiptBusiness: isFeatureEnabled(
      "online-shopping-receipt-business-feature-toggle"
    ),
    onlineShoppingReceiptPersonal: isFeatureEnabled(
      "online-shopping-receipt-personal-feature-toggle"
    ),
    securityPreferenceCustomerType: isFeatureEnabled(
      "security-preference-customer-type-feature-toggle"
    ),
  };
}

function isFeatureEnabled(metaProperty: string): boolean {
  return getMetaTagContent(metaProperty) === "true";
}

export function parseReturnUrls(metaProperty: string): string[] {
  try {
    const returnUrls = getMetaTagContent(metaProperty)?.trim();
    return returnUrls ? returnUrls.split(",") : [];
  } catch {
    return [];
  }
}

export default configuration;
