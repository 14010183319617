import "url-search-params-polyfill";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import store from "./store/Store";
import * as serviceWorker from "./serviceWorker";
import { EmailVerifiedPage } from "./components/emailVerified/EmailVerifiedPage";
import { CCPTheme, ColesClientName } from "ccp-common-ui-components";
import { history } from "./common/utils/history";
import configuration from "./config/Configuration";
import { TermsAndConditionsModalContainer } from "./common/modals/TermsAndConditionsModalContainer";
import { PrivacyPolicyModalContainer } from "./common/modals/PrivacyPolicyModalContainer";
import { ActionModalContainer } from "./common/modals/ActionModalContainer";
import { LogoutRedirect } from "./components/logout/LogoutRedirect";
import { AddressActionModalContainer } from "./common/modals/AddressActionModalContainer";
import { TeamMemberUnlinkModalContainer } from "./components/teamMember/TeamMemberUnlinkModalContainer";
import { FlybuysUnlinkModalContainer } from "./components/flybuys/FlybuysUnlinkModalContainer";
import { CollectionStatementModalContainer } from "./common/modals/CollectionStatementModalContainer";
import ReactDOM from "react-dom";

// Not fully compatible with React 18
// Ref: https://github.com/dequelabs/axe-core-npm/issues/500
if (configuration.isDevelopmentToolsEnabled) {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CCPTheme clientName={ColesClientName.ColesApp}>
        <Router history={history}>
          <Switch>
            <Route exact path="/email-verified">
              <EmailVerifiedPage />
            </Route>
            <Route path="/logout">
              <LogoutRedirect />
            </Route>
            <Route path="/" component={App} />
          </Switch>
        </Router>
        <TermsAndConditionsModalContainer />
        <PrivacyPolicyModalContainer />
        <CollectionStatementModalContainer />
        <ActionModalContainer />
        <TeamMemberUnlinkModalContainer />
        <FlybuysUnlinkModalContainer />
        <AddressActionModalContainer />
      </CCPTheme>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
