import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowPrivacyPolicy,
  setShowTermsAndConditions,
  setShowDeleteAccount,
  setShowCollectionStatement,
} from "../store/user-acceptances/UserAcceptancesSlice";
import { RootState } from "../store/Store";

const Container = styled.div`
  align-self: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  font-family: "Source Sans";
  font-size: 16px;
  line-height: 22px;
  margin: 4px 0;
`;

const Link = styled.a`
  color: #414240;
  text-decoration: underline;
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    padding-top: 12px;
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 9px;
  border-top: 1px solid #414240;
  margin: 0 15px;
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    margin-top: 18px;
  }
`;

function AppFooter() {
  const dispatch = useDispatch();
  const pendingDeletion = useSelector(
    (state: RootState) => state.customerProfile.profileFields?.pendingDeletion
  );

  return (
    <Container>
      <Link
        href=""
        onClick={(e) => {
          e.preventDefault();
          dispatch(setShowPrivacyPolicy(true));
        }}
      >
        Privacy Policy
      </Link>
      <Separator />
      <Link
        href=""
        onClick={(e) => {
          e.preventDefault();
          dispatch(setShowCollectionStatement(true));
        }}
      >
        Collection Notice
      </Link>
      <Separator />
      <Link
        href=""
        onClick={(e) => {
          e.preventDefault();
          dispatch(setShowTermsAndConditions(true));
        }}
      >
        Terms &amp; Conditions
      </Link>

      {!pendingDeletion && (
        <>
          <Separator />
          <Link
            href=""
            onClick={(e) => {
              e.preventDefault();
              dispatch(setShowDeleteAccount(true));
            }}
          >
            Delete my account
          </Link>
        </>
      )}
    </Container>
  );
}

export default AppFooter;
