import { MarketingPreference } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";

function getPreferenceValue(
  preferences: Array<any>,
  group: string,
  name: string
): string | null {
  const item = preferences.find(
    (element) => element.group === group && element.name === name
  );
  if (!item) {
    return null;
  }

  return item.value?.toString();
}

const profilePageImpression = (
  returnTo: string,
  userDetails: any,
  marketingPreferences: Array<MarketingPreference>
) => {
  (window as any).colData = (window as any).colData || [];
  (window as any).colData.push({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Profile Management Page",
      },
      customer: {
        auth_state: "authenticated",
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        mobile: userDetails.mobile,
        preferredStore: {
          displayAddress: userDetails.preferredStore?.displayAddress,
          status: userDetails.preferredStore?.status,
        },
        dateOfBirth: userDetails.dateOfBirth,
        homePhone: userDetails.homePhone,
        locality: userDetails.locality?.postcode,
        isMobileVerified: userDetails.isMobileVerified,
        mfaPreference: userDetails.mfaPreference,
        pendingDeletion: userDetails.pendingDeletion,
        profileId: "",
        preferences: [
          {
            group: "communication.marketing",
            name: "brand.supermarket.content.catalogue.mode.email",
            value: getPreferenceValue(
              marketingPreferences,
              "communication.marketing",
              "brand.supermarket.content.catalogue.mode.email"
            ),
            type: "boolean",
          },
          {
            group: "communication.marketing",
            name: "brand.supermarket.content.catalogue.mode.sms",
            value: getPreferenceValue(
              marketingPreferences,
              "communication.marketing",
              "brand.supermarket.content.catalogue.mode.sms"
            ),
            type: "boolean",
          },
          {
            group: "communication.marketing",
            name: "brand.cexp.content.all.mode.email",
            value: getPreferenceValue(
              marketingPreferences,
              "communication.marketing",
              "brand.cexp.content.all.mode.email"
            ),
            type: "boolean",
          },
          {
            group: "communication.marketing",
            name: "brand.cexp.content.all.mode.sms",
            value: getPreferenceValue(
              marketingPreferences,
              "communication.marketing",
              "brand.cexp.content.all.mode.sms"
            ),
            type: "boolean",
          },
        ],
        teamMember: {
          memberId: userDetails.teamMember?.memberId,
          loyaltyAccountId: userDetails.teamMember?.loyaltyAccountId,
          hasError: userDetails.teamMember?.hasError,
        },
        flybuys: {
          memberId: userDetails.flybuys?.memberId,
          linkFlybuysUrl: userDetails.flybuys?.linkFlybuysUrl,
          loyaltyAccountId: userDetails.flybuys?.loyaltyAccountId,
          hasError: userDetails.flybuys?.hasError,
        },
      },
      view: {
        context: {
          view_type: "profile",
          view_type_details: returnTo,
        },
      },
    },
  });
};

export { profilePageImpression };
