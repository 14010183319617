import { useDispatch, useSelector } from "react-redux";
import { PrivacyPolicyModal } from "ccp-common-ui-components";
import { RootState } from "../../store/Store";
import { setShowPrivacyPolicy } from "../../store/user-acceptances/UserAcceptancesSlice";

export function PrivacyPolicyModalContainer() {
  const dispatch = useDispatch();
  const { showPrivacyPolicy } = useSelector(
    (state: RootState) => state.userAcceptances
  );

  const closeModal = () => {
    dispatch(setShowPrivacyPolicy(false));
  };

  return (
    <PrivacyPolicyModal
      isOpen={showPrivacyPolicy}
      shouldCloseOnEsc={true}
      onRequestClose={() => closeModal()}
    />
  );
}
